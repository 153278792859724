import React from 'react';

function FooterStrip(props) {
    return (
        <div className='primary-dark-background p-2'>
            <div className='container'>

            <p className='m-0' style={{
                padding: "10px 10px",
                color: "var(--secondary-color)",
                fontSize: "14px"
                }}>&copy; {new Date().getFullYear()} Neya. All rights reserved. Designed by <a href="https://wa.me/+919908808044?text=Hi" target='_blank' className='color-secondary text-decoration-underline'>Shift2Digital</a>.</p>
            </div>
        </div>
    );
}

export default FooterStrip;